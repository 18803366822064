import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CircleMarker, Polyline, Marker } from "react-leaflet";
import { yawActions } from "../../_actions";
import { YAW_EDIT_TYPES } from "../../_constants";
import { history } from "../../_helpers";

const COLOR_RED = "#FF6106";
const COLOR_BLUE = "#3F83FF";

const inRange = function (num, start, end) {
  return num >= Math.min(start, end) && num <= Math.max(start, end) && end > -1;
};

class VideoWalkPath extends React.Component {
  constructor(props) {
    super(props);
  }

  onClickPoint(frameIndex, e) {
    const { videowalk } = this.props;

    if (e.originalEvent.shiftKey && this.props.isYawEditing) {
      this.props.selectRangeFrames(frameIndex);
    } else {
      let newPath = `/${videowalk.uuid}/${frameIndex}`;
      if (this.props.endpointCorrection) {
        newPath += "?mode=endpoint-correction";
      }
      history.push(newPath);
    }
  }

  frameColor(index) {
    const { isYawEditing, firstFrameIndex, secondFrameIndex, yawEditingType } = this.props;

    if (isYawEditing && yawEditingType === YAW_EDIT_TYPES.RANGE) {
      if (firstFrameIndex === index) return COLOR_BLUE;
      if (inRange(index, firstFrameIndex, secondFrameIndex)) return COLOR_BLUE;
    }

    return COLOR_RED;
  }

  renderEndPoints() {
    const {
      videowalk,
      drawing: { mapWidth, mapHeight },
    } = this.props;

    const { start_x, start_y, end_x, end_y } = videowalk;

    return (
      <div>
        <CircleMarker
          onClick={(e) => this.onClickPoint(0, e)}
          center={{ lat: start_y * mapHeight, lon: start_x * mapWidth }}
          color="#fff"
          fillColor="#03cc4b"
          fillOpacity="0.6"
        />
        <CircleMarker
          onClick={(e) => this.onClickPoint(videowalk.path.cameras.length - 1, e)}
          center={{ lat: end_y * mapHeight, lon: end_x * mapWidth }}
          color="#fff"
          fillColor="#e6221c"
          fillOpacity="0.6"
        />
      </div>
    );
  }

  renderNodePoints() {
    const {
      videowalk,
      pagination,
      pageStart,
      pageEnd,
      drawing: { mapWidth, mapHeight },
    } = this.props;

    const scaledCameraPoints = videowalk.path.cameras.map((frame) => {
      return [frame.floorplan_y * mapHeight, frame.floorplan_x * mapWidth];
    });

    return scaledCameraPoints.map((point, index) => {
      if (pagination && (index < pageStart || index > pageEnd)) return false;
      // Filter frame points that user can only see and access by checking its property - "render"
      if (!videowalk.path.cameras[index].render) return false;
      // Skip rendering start/end node points
      if (index === 0 || index === videowalk.path.cameras.length - 1) return false;

      const fillColor = this.frameColor(index);

      return fillColor ? (
        <CircleMarker
          key={index}
          onClick={(e) => this.onClickPoint(index, e)}
          center={point}
          color="#fff"
          fillColor={fillColor}
          opacity="1"
          fillOpacity="1"
          radius={6}
        />
      ) : (
        false
      );
    });
  }

  renderPathLine() {
    const {
      videowalk,
      pagination,
      pageStart,
      pageEnd,
      drawing: { mapWidth, mapHeight },
    } = this.props;

    const scaledCameraPoints = videowalk.path.cameras.map((frame, index) => {
      if (pagination && (index < pageStart || index > pageEnd)) return false;
      return [frame.floorplan_y * mapHeight, frame.floorplan_x * mapWidth];
    });

    return (
      <Polyline
        positions={scaledCameraPoints.filter((point) => !!point)}
        className="testid-path"
        clickable={false}
        color="#FF6106"
        opacity="0.5"
        key={videowalk.id}
      />
    );
  }

  render() {
    const { videowalk, endpointCorrection } = this.props;

    if (!videowalk.path) return this.renderEndPoints();

    return (
      <>
        {!endpointCorrection && this.renderPathLine()}
        {!endpointCorrection && this.renderNodePoints()}
        {this.renderEndPoints()}
      </>
    );
  }
}

VideoWalkPath.propTypes = {
  videowalk: PropTypes.object.isRequired,
  drawing: PropTypes.object.isRequired,
  selectRangeFrames: PropTypes.func,
  isYawEditing: PropTypes.bool,
  firstFrameIndex: PropTypes.number,
  secondFrameIndex: PropTypes.number,
  yawEditingType: PropTypes.string,
  pagination: PropTypes.bool,
  pageStart: PropTypes.number,
  pageEnd: PropTypes.number,
  endpointCorrection: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  videowalk: state.videowalkReducer.videowalk,
  lastSavedAt: state.editReducer.lastSavedAt,
  drawing: state.drawingReducer.drawing,
  isYawEditing: state.yawReducer.isEditing,
  firstFrameIndex: state.yawReducer.firstFrameIndex,
  secondFrameIndex: state.yawReducer.secondFrameIndex,
  yawEditingType: state.yawReducer.editingType,
  pagination: state.globalReducer.pagination,
  pageStart: state.globalReducer.pageStart,
  pageEnd: state.globalReducer.pageEnd,
  endpointCorrection: state.globalReducer.endpointCorrection,
});

const mapDispatchToProps = {
  selectRangeFrames: yawActions.selectRangeFrames,
};

const VideoWalkPathHOC = connect(mapStateToProps, mapDispatchToProps)(VideoWalkPath);

export { VideoWalkPathHOC as VideoWalkPath };
