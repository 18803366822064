import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { EDITING_TYPES } from "../../_constants";
import { editActions, userActions, videowalkActions } from "../../_actions";
import { trackEvent } from "../../_helpers/analytics";
import { SAVE_HOTKEY, HOLD_HOTKEY } from "../../_constants/hotkey.constants";
import { getFrameMode } from "../../_helpers/leaflet";

function EndpointEditButtons() {
  const editingType = useSelector((state) => state.editReducer.editingType);
  const isSaving = useSelector((state) => state.videowalkReducer.isSaving);
  const screenMode = useSelector((state) => state.globalReducer.screenMode);

  const dispatch = useDispatch();

  const onChangeEditingType = (newType) => {
    dispatch(editActions.changeEditingType(newType));
  };

  const onClickCancel = () => {
    dispatch(editActions.stopEditing());
  };

  const onClickSave = (useHotkey = false) => {
    if (!isSaving) {
      trackEvent("Save Triggered", { useHotkey });
      dispatch(videowalkActions.updateVideoWalk());
    }
  };

  useHotkeys(
    SAVE_HOTKEY,
    (event) => {
      event.preventDefault();
      if (!isSaving) {
        onClickSave(true);
      }
    },
    [isSaving]
  );

  useHotkeys(
    HOLD_HOTKEY,
    () => {
      if (editingType === EDITING_TYPES.HOLD) {
        onClickCancel();
      } else {
        trackEvent("Hold Mode Triggered", { useHotkey: true });
        onChangeEditingType(EDITING_TYPES.HOLD);
      }
    },
    [editingType]
  );

  const onClickReset = () => {
    dispatch(editActions.resetEditing());
  };

  return (
    <div className={`videowalk-adjust-group ${getFrameMode(screenMode)}`}>
      {/* <a className="btn-edit btn-reset btn-warn" onClick={onClickReset}>
        Reset
      </a> */}
      <a
        className={`btn-edit btn-rounded ${editingType === EDITING_TYPES.MOVE && "btn-orange"}`}
        data-testid="start-stop-button"
        onClick={() => onChangeEditingType(EDITING_TYPES.MOVE)}
      >
        Start / Stop
      </a>
      <a
        className={`btn-edit btn-save btn-orange ${isSaving && "btn-disabled"}`}
        data-testid="save-button"
        onClick={() => onClickSave()}
        disabled={isSaving}
      >
        {isSaving ? "Saving..." : "Save"}
      </a>
      <a className="btn-edit btn-cancel" onClick={onClickCancel}>
        Cancel
      </a>
    </div>
  );
}

export { EndpointEditButtons };
