import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import config from "config";
import { history } from "../_helpers";
import { PrivateRoute } from "../_components";
import { MainPage } from "../MainPage";
import { LoginPage } from "../LoginPage";
import { trackWorklistUser } from "../_helpers/analytics";

import "react-toastify/dist/ReactToastify.css";
import "../_styles/tailwind.css";
import "../_styles/custom.scss";

function App() {
  if (location.search.length > 0) {
    const urlParams = new URLSearchParams(location.search);
    const userEmail = urlParams.get("user");

    if (userEmail) {
      trackWorklistUser(userEmail);

      urlParams.delete("user");
      // Clear email parameter from url once it is stored in localstorage.
      location.replace(location.pathname + "?" + urlParams.toString());
    }
  }

  return (
    <div className="flex flex-col h-screen">
      <ToastContainer />
      <Router history={history}>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <PrivateRoute path="/" component={MainPage} />
        </Switch>
      </Router>
    </div>
  );
}

export { App };
