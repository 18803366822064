import { apiConstants, editConstants, userConstants } from "../_constants";

const INITIAL_STATE = { isSaving: false, hasUnsavedChanges: false };

export function videowalkReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case apiConstants.GET_VIDEOWALK_SUCCESS:
      return {
        ...state,
        videowalk: action.videowalk,
      };
    case apiConstants.GET_VIDEOWALK_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case userConstants.CLICKED_FLIP_BUTTON:
    case userConstants.HIT_YAW_LEFT_HOTKEY:
    case userConstants.HIT_YAW_RIGHT_HOTKEY:
    case userConstants.DRAGGED_START_NODE:
    case userConstants.DRAGGED_END_NODE:
    case userConstants.DRAGGED_MIDDLE_NODE:
    case editConstants.EDIT_FINISH_SPREAD:
    case userConstants.DRAGGED_SCALE_TOP_HANDLE:
    case userConstants.DRAGGED_SCALE_BOTTOM_HANDLE:
    case userConstants.DRAGGED_SCALE_RIGHT_HANDLE:
    case userConstants.DRAGGED_SCALE_LEFT_HANDLE:
      return {
        ...state,
        hasUnsavedChanges: true,
      };
    case apiConstants.UPDATE_VIDEOWALK_REQUEST:
      return {
        ...state,
        isSaving: true,
        hasUnsavedChanges: false,
      };
    case apiConstants.UPDATE_VIDEOWALK_SUCCESS:
      return {
        ...state,
        videowalk: action.videowalk,
        isSaving: false,
      };
    case apiConstants.UPDATE_VIDEOWALK_FAILURE:
      return {
        ...state,
        error: action.error,
        isSaving: false,
        hasUnsavedChanges: true,
      };
    default:
      return state;
  }
}
