import config from "config";
import honeybadger from "../_helpers/honeybadger";
import { authHeader } from "../_helpers";

export const apiService = {
  getDrawing,
  getVideoWalk,
  updateVideoWalk,
  getVideoWalkFromHasura,
};

function getDrawing(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiServer}/api/v1/areas/${id}`, requestOptions).then(handleResponse);
}

function getVideoWalk(uuid) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${config.apiServer}/api/v1/video_walks/${uuid}`, requestOptions).then(
    handleResponse
  );
}

function updateStartStopPoints(uuid, startPos, endPos) {
  return fetch(`${config.apiServer}/api/graphql`, {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      query: `
          mutation VideoWalkUpdate($id: ID!, $startPos: GeoCoord!, $endPos: GeoCoord!) {
            videowalkUpdate(input: { id: $id, startPos: $startPos, endPos: $endPos }) {
              errors
              success
            }
          }
        `,
      variables: {
        startPos,
        endPos,
        id: uuid,
      },
    }),
  });
}

function updateFrames(uuid, frames) {
  return fetch(`${config.apiServer}/api/graphql`, {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      query: `
          mutation VideoWalkFrameUpdate($id: ID!, $frames: [VideoWalkFrameInput!]!) {
            videowalkFrameUpdate(input: { id: $id, frames: $frames }) {
              errors
              success
            }
          }
        `,
      variables: {
        frames,
        id: uuid,
      },
    }),
  });
}

function updateVideoWalk(videowalk, lastSavedVideowalk) {
  const startPos = [videowalk.start_x, videowalk.start_y];
  const endPos = [videowalk.end_x, videowalk.end_y];
  const frames = videowalk.path.cameras.map((frame) => {
    return {
      id: frame.framenum,
      rotation: frame.rotation || frame.yaw || frame.recon_yaw,
      floorplanX: frame.floorplan_x || frame.recon_x2d,
      floorplanY: frame.floorplan_y || frame.recon_y2d,
    };
  });
  const uuid = videowalk.uuid;
  return Promise.all([updateStartStopPoints(uuid, startPos, endPos), updateFrames(uuid, frames)])
    .then((responses) => Promise.all(responses.map((r) => r.json())))
    .then((jsons) => {
      jsons.forEach((json) => {
        if (json.errors && json.errors.length) {
          throw new Error(json.errors[0].message);
        }
      });
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (response.status === 404) {
      return Promise.reject(false);
    }
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      honeybadger.notify(error);
      return Promise.reject(error);
    }

    return data;
  });
}

function getVideoWalkFromHasura(uuid) {
  return fetch(`${config.hasuraApiHost}/v1/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-hasura-admin-secret": config.hasuraApiSecret,
    },
    body: JSON.stringify({
      query: `
        query GetFrames {
          videoWalk(where: {sitedoc_uuid: {_eq: "${uuid}"}, videoWalkFrameImageRuns: {run_name: {_eq: "raw_frames"}}}) {
            id
            videoWalkFrameImages {
              index
              file {
                signed_url {
                  url
                }
              }
            }
          }
        }
      `,
    }),
  }).then(handleResponse);
}
