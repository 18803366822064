import { editConstants, globalConstants, EDITING_TYPES, trackConstants } from "../_constants";
import {
  cloneVideoWalk,
  calcVideoPathFrame,
  flipVideoPath,
  moveVideoNode,
  moveVideoNodeV2,
  scaleVideoPath,
  recomputeVideoPath,
  spreadVideoWalk,
  initiateVideoPathFromPSMatrix,
  updateEndpoints,
} from "../_helpers/videowalk";
import { trackEvent } from "../_helpers/analytics";

export const editActions = {
  startEditing,
  stopEditing,
  changeEditingType,
  setEditingNode,
  setHoldNode,
  setScaleDirection,
  setMovingNodeType,
  handleMouseMove,
  cleanEditingVars,
  finishSpreadEdit,
  resetEditing,
};

function startEditing() {
  return (dispatch, getState) => {
    const { videowalk } = getState().videowalkReducer;
    const { endpointCorrection } = getState().globalReducer;

    trackEvent("Path Adjustment");

    dispatch({ type: trackConstants.INIT });
    dispatch({ type: editConstants.START_EDITING, editingVideoWalk: cloneVideoWalk(videowalk) });
    if (endpointCorrection) {
      dispatch({ type: editConstants.CHANGE_EDITING_TYPE, editingType: EDITING_TYPES.MOVE });
    }
  };
}

function stopEditing() {
  return (dispatch) => {
    dispatch({ type: editConstants.STOP_EDITING });
    dispatch({ type: globalConstants.ACTIVATE_PAGINATION, pagination: false });
  };
}

function changeEditingType(editingType) {
  return (dispatch, getState) => {
    const videowalk = getState().editReducer.editingVideoWalk;
    const { pagination, pageStart, pageEnd } = getState().globalReducer;

    if (editingType === EDITING_TYPES.SCALE) {
      calcVideoPathFrame(videowalk);
    } else if (editingType === EDITING_TYPES.FLIP) {
      flipVideoPath(videowalk);
    } else if (editingType === EDITING_TYPES.SPREAD || editingType === EDITING_TYPES.HOLD) {
      dispatch({
        type: editConstants.SET_HOLD_NODE,
        editingHoldStart: pagination ? pageStart : 0,
        editingHoldEnd: pagination ? pageEnd : videowalk.path.cameras.length - 1,
      });
    }

    dispatch({ type: editConstants.CHANGE_EDITING_TYPE, editingType });
    dispatch({ type: trackConstants.NODE_ADJUST, editingType });
  };
}

function setHoldNode(nodeIndex) {
  return (dispatch, getState) => {
    const lastHoldClick = getState().editReducer.lastHoldClick;

    dispatch({
      type: editConstants.SET_HOLD_NODE,
      [lastHoldClick ? "editingHoldEnd" : "editingHoldStart"]: nodeIndex,
      lastHoldClick: !lastHoldClick,
    });
  };
}

function setEditingNode(e, nodeIndex) {
  if (e.originalEvent.shiftKey) {
    trackEvent("Set Hold Point", { useHotkey: false });
    return setHoldNode(nodeIndex);
  } else {
    return { type: editConstants.SET_EDITING_NODE, clickedEditingNode: nodeIndex };
  }
}

function setScaleDirection(scaleDirection) {
  return { type: editConstants.SET_SCALE_DIRECTION, scaleDirection };
}

function setMovingNodeType(movingNodeType) {
  return { type: editConstants.SET_MOVING_NODE_TYPE, movingNodeType };
}

function handleMouseMove(e) {
  return (dispatch, getState) => {
    const drawing = getState().drawingReducer.drawing;
    const { endpointCorrection } = getState().globalReducer;
    const {
      editingVideoWalk,
      editingType,
      editingHoldStart,
      editingHoldEnd,
      clickedEditingNode,
      scaleDirection,
      movingNodeType,
      isDragging,
    } = getState().editReducer;

    if (!isDragging) return;

    const { mapWidth, mapHeight } = drawing;
    const lastNode = editingVideoWalk.path.cameras.length - 1;

    const x = e.latlng.lng / mapWidth;
    const y = e.latlng.lat / mapHeight;
    let shouldUpdate = false;

    if (editingType === EDITING_TYPES.MOVE) {
      if (endpointCorrection) {
        shouldUpdate = updateEndpoints(editingVideoWalk, movingNodeType, x, y);
      } else {
        shouldUpdate = recomputeVideoPath(editingVideoWalk, movingNodeType, x, y);
      }
    }
    if (editingType === EDITING_TYPES.SCALE) {
      shouldUpdate = scaleVideoPath(editingVideoWalk, scaleDirection, x, y);
    }
    if (editingType === EDITING_TYPES.SINGLE) {
      shouldUpdate = moveVideoNode(editingVideoWalk, clickedEditingNode, x, y);
    }
    if (editingType === EDITING_TYPES.NODE) {
      shouldUpdate = moveVideoNodeV2(editingVideoWalk, clickedEditingNode, 0, lastNode, x, y);
    }
    if (editingType === EDITING_TYPES.HOLD) {
      let hStart = Math.min(editingHoldStart, editingHoldEnd);
      let hEnd = Math.max(editingHoldStart, editingHoldEnd);

      if (clickedEditingNode < hStart) {
        hEnd = hStart;
        hStart = 0;
      }
      if (clickedEditingNode > hEnd) {
        hStart = hEnd;
        hEnd = lastNode;
      }

      shouldUpdate = moveVideoNodeV2(editingVideoWalk, clickedEditingNode, hStart, hEnd, x, y);
    }

    if (shouldUpdate) {
      dispatch({ type: editConstants.FORCE_RENDER_EDITING_PATH });
    }
  };
}

function finishSpreadEdit() {
  return (dispatch, getState) => {
    const { editingVideoWalk, editingHoldStart, editingHoldEnd } = getState().editReducer;

    dispatch({ type: editConstants.EDIT_FINISH_SPREAD });
    spreadVideoWalk(editingVideoWalk, editingHoldStart, editingHoldEnd);
    dispatch({ type: editConstants.FORCE_RENDER_EDITING_PATH });
  };
}

function cleanEditingVars() {
  return { type: editConstants.CLEAN_EDIT_STATUS };
}

function resetEditing() {
  return (dispatch, getState) => {
    const { editingVideoWalk } = getState().editReducer;

    initiateVideoPathFromPSMatrix(editingVideoWalk);
    flipVideoPath(editingVideoWalk);
    dispatch({ type: editConstants.FORCE_RENDER_EDITING_PATH });
    dispatch({ type: trackConstants.NODE_ADJUST, editingType: EDITING_TYPES.RESET });
  };
}
