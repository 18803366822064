import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { globalActions } from "../../_actions";

function PaginationButtons() {
  const {
    nodeIncrement,
    pagination,
    isPathEditing,
    isYawEditing,
    endpointCorrection,
  } = useSelector((state) => ({
    screenMode: state.globalReducer.screenMode,
    nodeIncrement: state.globalReducer.nodeIncrement,
    pagination: state.globalReducer.pagination,
    isPathEditing: state.editReducer.isEditing,
    isYawEditing: state.yawReducer.isEditing,
    endpointCorrection: state.globalReducer.endpointCorrection,
  }));

  const dispatch = useDispatch();

  const onChangeIncrement = (event) => {
    dispatch(globalActions.changeNodeIncrement(event.target.value));
  };

  const onClickPagination = () => {
    dispatch(globalActions.activatePagination(!pagination));
  };

  const onClickPlus = () => {
    if (!pagination) return;
    dispatch(globalActions.showNextPage());
  };

  const onClickMinus = () => {
    if (!pagination) return;
    dispatch(globalActions.hidePrevPage());
  };

  if (endpointCorrection) return false;
  if (!(isPathEditing || isYawEditing)) return false;

  return (
    <div className="frame-pagination-group">
      <div className="button-group">
        <a
          className={`btn-pagination ${pagination ? "btn-active" : ""}`}
          onClick={onClickPagination}
        >
          {pagination ? "Show All" : "Hide"}
        </a>
        <a className="btn-next-page" title="Press <Q>" onClick={onClickPlus}>
          <i className="icon-Plus text-md" />
        </a>
        <a className="btn-prev-page" title="Press <E>" onClick={onClickMinus}>
          <i className="icon-Minus text-md" />
        </a>
      </div>
      <div className="input-group">
        Increment
        <input type="number" value={nodeIncrement} onChange={onChangeIncrement} min="5" max="500" />
        Nodes
      </div>
    </div>
  );
}

export { PaginationButtons };
