import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { CircleMarker, Polyline, Rectangle } from "react-leaflet";
import { editActions, userActions } from "../../_actions";
import { EDITING_TYPES } from "../../_constants/edit.constants";
import { HOLD_CURRENT_NODE_HOTKEY } from "../../_constants/hotkey.constants";
import { trackEvent } from "../../_helpers/analytics";

function VideoWalkEditingPath() {
  const {
    drawing,
    isEditing,
    editingType,
    editingVideoWalk,
    editingHoldStart,
    editingHoldEnd,
    pagination,
    pageStart,
    pageEnd,
    frameIndex,
    endpointCorrection,
  } = useSelector((state) => ({
    drawing: state.drawingReducer.drawing,
    isEditing: state.editReducer.isEditing,
    editingType: state.editReducer.editingType,
    editingVideoWalk: state.editReducer.editingVideoWalk,
    lastEditedAt: state.editReducer.lastEditedAt,
    editingHoldStart: state.editReducer.editingHoldStart,
    editingHoldEnd: state.editReducer.editingHoldEnd,
    pagination: state.globalReducer.pagination,
    pageStart: state.globalReducer.pageStart,
    pageEnd: state.globalReducer.pageEnd,
    frameIndex: state.globalReducer.frameIndex,
    endpointCorrection: state.globalReducer.endpointCorrection,
  }));
  const dispatch = useDispatch();

  const handleEditingNodeClick = (e, nodeIndex) => {
    e.target._map.dragging.disable();
    dispatch(editActions.setEditingNode(e, nodeIndex));
  };

  const handleScaleFrameClick = (e, direction) => {
    e.target._map.dragging.disable();
    dispatch(editActions.setScaleDirection(direction));
  };

  const handleEndNodeClick = (e, nodeType) => {
    e.target._map.dragging.disable();
    dispatch(editActions.setMovingNodeType(nodeType));
  };

  useHotkeys(
    HOLD_CURRENT_NODE_HOTKEY,
    () => {
      if (!isEditing || editingType !== EDITING_TYPES.HOLD) {
        return false;
      } else {
        trackEvent("Set Hold Point", { useHotkey: true });
        dispatch(editActions.setHoldNode(frameIndex));
      }
    },
    [frameIndex, isEditing, editingType]
  );

  if (!isEditing) return false;

  const { mapHeight, mapWidth } = drawing;

  const editingCameraPoints = editingVideoWalk.path.cameras.map((frame, index) => {
    if (pagination && (index < pageStart || index > pageEnd)) return false;
    return [frame.floorplan_y * mapHeight, frame.floorplan_x * mapWidth];
  });

  const { start_x, start_y, end_x, end_y } = editingVideoWalk;

  if (!endpointCorrection) {
    var pathLine = (
      <Polyline positions={editingCameraPoints.filter((point) => !!point)} weight={3} color="red" />
    );
  }
  if (editingType === EDITING_TYPES.SCALE) {
    const minLat = editingVideoWalk.min_y * mapHeight;
    const minLon = editingVideoWalk.min_x * mapWidth;
    const maxLat = editingVideoWalk.max_y * mapHeight;
    const maxLon = editingVideoWalk.max_x * mapWidth;

    var frameControl = [
      <Rectangle
        key={1}
        bounds={[
          [minLat, minLon],
          [maxLat, maxLon],
        ]}
        color="orange"
        opacity={0.5}
        weight={3}
        dashArray="3,7"
      />,
      <Rectangle
        key={2}
        bounds={[
          [minLat, (minLon + maxLon - 30) / 2],
          [minLat, (minLon + maxLon + 30) / 2],
        ]}
        color="orange"
        opacity={1}
        weight={8}
        className="path-frame-border ns testid-scale-bottom-handle"
        onMouseDown={(e) => handleScaleFrameClick(e, 1)}
        onMouseUp={(e) => dispatch(userActions.draggedScaleBottomHandle(e))}
      />,
      <Rectangle
        key={3}
        bounds={[
          [maxLat, (minLon + maxLon - 30) / 2],
          [maxLat, (minLon + maxLon + 30) / 2],
        ]}
        color="orange"
        opacity={1}
        weight={8}
        className="path-frame-border ns testid-scale-top-handle"
        onMouseDown={(e) => handleScaleFrameClick(e, 2)}
        onMouseUp={(e) => dispatch(userActions.draggedScaleTopHandle(e))}
      />,
      <Rectangle
        key={4}
        bounds={[
          [(minLat + maxLat - 30) / 2, minLon],
          [(minLat + maxLat + 30) / 2, minLon],
        ]}
        color="orange"
        opacity={1}
        weight={8}
        className="path-frame-border ew testid-scale-left-handle"
        onMouseDown={(e) => handleScaleFrameClick(e, 3)}
        onMouseUp={(e) => dispatch(userActions.draggedScaleLeftHandle(e))}
      />,
      <Rectangle
        key={5}
        bounds={[
          [(minLat + maxLat - 30) / 2, maxLon],
          [(minLat + maxLat + 30) / 2, maxLon],
        ]}
        color="orange"
        opacity={1}
        weight={8}
        className="path-frame-border ew testid-scale-right-handle"
        onMouseDown={(e) => handleScaleFrameClick(e, 4)}
        onMouseUp={(e) => dispatch(userActions.draggedScaleRightHandle(e))}
      />,
    ];
  }

  if (
    editingType === EDITING_TYPES.NODE ||
    editingType === EDITING_TYPES.SINGLE ||
    editingType === EDITING_TYPES.HOLD ||
    editingType === EDITING_TYPES.SPREAD
  ) {
    var nodePoints = editingCameraPoints.map((point, nodeIndex) => {
      if (!point) return false;
      return (
        <CircleMarker
          key={nodeIndex}
          pane="markerPane"
          bubblingMouseEvents={false}
          onMouseDown={(e) => handleEditingNodeClick(e, nodeIndex)}
          onMouseUp={(e) => dispatch(userActions.draggedMiddleNode(nodeIndex, e))}
          center={point}
          className={`testid-node-${nodeIndex}`}
          color="#8FFF42"
          fillOpacity="0"
          opacity="1"
          radius={5}
          weight={2}
        />
      );
    });
  }

  if (
    (editingType === EDITING_TYPES.HOLD || editingType === EDITING_TYPES.SPREAD) &&
    editingCameraPoints[editingHoldStart] &&
    editingCameraPoints[editingHoldEnd]
  ) {
    var holdPoints = [
      <CircleMarker
        key={editingHoldStart}
        pane="markerPane"
        center={editingCameraPoints[editingHoldStart]}
        color="#3F83FF"
        fillOpacity="1"
        opacity="1"
        radius={5}
        weight={2}
      />,
      <CircleMarker
        key={editingHoldEnd}
        pane="markerPane"
        center={editingCameraPoints[editingHoldEnd]}
        color="#3F83FF"
        fillOpacity="1"
        opacity="1"
        radius={5}
        weight={2}
      />,
    ];
  }

  if (editingType === EDITING_TYPES.MOVE) {
    var endpoints = [
      <CircleMarker
        key="start"
        onMouseDown={(e) => handleEndNodeClick(e, "start")}
        onMouseUp={(e) => dispatch(userActions.draggedStartNode(e))}
        center={{ lat: start_y * mapHeight, lon: start_x * mapWidth }}
        color="red"
        className="testid-start-node"
        radius={8}
      />,
      <CircleMarker
        key="end"
        onMouseDown={(e) => handleEndNodeClick(e, "end")}
        onMouseUp={(e) => dispatch(userActions.draggedEndNode(e))}
        center={{ lat: end_y * mapHeight, lon: end_x * mapWidth }}
        color="red"
        className="testid-stop-node"
        radius={8}
      />,
    ];
  }
  return (
    <>
      {pathLine}
      {endpoints}
      {frameControl}
      {nodePoints}
      {holdPoints}
    </>
  );
}

export { VideoWalkEditingPath };
