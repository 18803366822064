import React from "react";
import { toast } from "react-toastify";
import moment from "moment";

import { trackActions, drawingActions } from ".";
import { apiConstants, editConstants } from "../_constants";
import { apiService } from "../_services";
import { preprocessVideoWalk, updateVideoWalkStartEndPoints } from "../_helpers/videowalk";
import { trackSavingVideoWalk } from "../_helpers/analytics";

export const videowalkActions = {
  getVideoWalk,
  updateVideoWalk,
  autoSave,
  getVideoWalkFromHasura,
};

const SavingContent = () => (
  <div>
    <div className="align-bottom inline-block loader ease-linear rounded-full border-2 border-t-2 border-gray-50 h-8 w-8" />
    <div className="inline-block align-middle pl-4">Saving VideoWalk...</div>
  </div>
);

function getVideoWalk(uuid) {
  return (dispatch) => {
    apiService.getVideoWalk(uuid).then(
      (videowalk) => {
        dispatch(drawingActions.getDrawing(videowalk.area_id));

        if (!videowalk.path || !videowalk.path.cameras || videowalk.path.cameras.length === 0) {
          dispatch(getVideoWalkFromHasura(videowalk, uuid));
          return;
        }

        let needUpdate = false;

        // If floorplan_x on the first frame is undefined, we're assuming the rest are undefined
        // as well as floorplan_y and the values need to be copied over from recon_x2d, recon_y2d
        if (videowalk && videowalk.path.cameras[0].floorplan_x === undefined) {
          preprocessVideoWalk(videowalk);
          needUpdate = true;
        }

        if (
          videowalk &&
          videowalk.path.cameras[0].rotation === undefined &&
          videowalk.path.cameras[0].yaw !== undefined
        ) {
          videowalk.path.cameras.forEach((cam, i) => {
            const frame = videowalk.path.cameras[i];
            frame.rotation = frame.yaw;
          });
          needUpdate = true;
        }

        if (
          videowalk &&
          videowalk.path.cameras[0].yaw === undefined &&
          videowalk.path.cameras[0].recon_yaw !== undefined
        ) {
          videowalk.path.cameras.forEach((cam, i) => {
            const frame = videowalk.path.cameras[i];
            frame.yaw = frame.recon_yaw;
          });
          needUpdate = true;
        }

        if (needUpdate) {
          apiService
            .updateVideoWalk(videowalk, null)
            .catch((error) => toast.error(error.toString()));
        }

        dispatch(success(videowalk));
      },
      (error) => {
        const errorMsg = error ? error.toString() : "VideoWalk Not Found!";
        dispatch(failure(errorMsg));
        toast.error(errorMsg);
      }
    );
  };

  function success(videowalk) {
    return { type: apiConstants.GET_VIDEOWALK_SUCCESS, videowalk };
  }
  function failure(error) {
    return { type: apiConstants.GET_VIDEOWALK_FAILURE, error };
  }
}

function getVideoWalkFromHasura(videowalk, uuid) {
  return (dispatch) => {
    apiService.getVideoWalkFromHasura(uuid).then(
      (result) => {
        const frameImages = result.data.videoWalk[0].videoWalkFrameImages.map(
          (e) => e.file.signed_url.url
        );
        videowalk.path = { cameras: [] };
        videowalk.frame_images = frameImages;

        dispatch(success(videowalk));
      },
      (error) => {
        const errorMsg = error ? error.toString() : "VideoWalk Not Found From Hasura!";
        dispatch(failure(errorMsg));
        toast.error(errorMsg);
      }
    );
  };
  function success(videowalk) {
    return { type: apiConstants.GET_VIDEOWALK_SUCCESS, videowalk };
  }
  function failure(error) {
    return { type: apiConstants.GET_VIDEOWALK_FAILURE, error };
  }
}

function updateVideoWalk() {
  return (dispatch, getState) => {
    const { account } = getState().accountReducer;
    const { isEditing: isYawEditing } = getState().yawReducer;
    const { isEditing: isPathEditing } = getState().editReducer;
    const { videowalk: lastSavedVideowalk } = getState().videowalkReducer;

    if (!isYawEditing && !isPathEditing) return;

    const { editReducer, yawReducer, trackReducer } = getState();
    const { endpointCorrection } = getState().globalReducer;
    const { editingVideoWalk } = isYawEditing ? yawReducer : editReducer;

    if (!endpointCorrection) {
      updateVideoWalkStartEndPoints(editingVideoWalk);
    }

    const savingToastId = toast.info(SavingContent, { autoClose: false });
    const saveStartedAt = moment();
    const trackStats = completeStats(trackReducer);

    dispatch({ type: apiConstants.UPDATE_VIDEOWALK_REQUEST, videoWalk: editingVideoWalk });

    return apiService.updateVideoWalk(editingVideoWalk, lastSavedVideowalk).then(
      (videowalk) => {
        trackStats.saving_time_in_milliseconds = moment().diff(saveStartedAt);

        trackSavingVideoWalk({ account, videowalk: editingVideoWalk, trackStats });

        dispatch(success(editingVideoWalk));
        dispatch(trackActions.reset());
        toast.dismiss(savingToastId);
        toast.success("Successfully saved VideoWalk!");
        dispatch({ type: editConstants.FORCE_RENDER_ORIGIN_PATH });
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function success(videowalk) {
    return { type: apiConstants.UPDATE_VIDEOWALK_SUCCESS, videowalk };
  }
  function failure(error) {
    return { type: apiConstants.UPDATE_VIDEOWALK_FAILURE, error };
  }
}

function autoSave() {
  return (dispatch, getState) => {
    const {
      videowalkReducer: { isSaving, hasUnsavedChanges },
    } = getState();

    if (!isSaving && hasUnsavedChanges) {
      dispatch(videowalkActions.updateVideoWalk());
    }
  };
}

function completeStats(stats) {
  stats.node_adjusting_time_in_seconds |= 0;

  if (stats.started_at) {
    stats.editing_time_in_seconds = moment().diff(stats.started_at, "seconds");
  }

  if (stats.node_adjust_started_at) {
    stats.node_adjusting_time_in_seconds += moment().diff(stats.node_adjust_started_at, "seconds");
  }

  delete stats.node_adjust_started_at;
  delete stats.started_at;
  return stats;
}
