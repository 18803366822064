import { trackConstants, EDITING_TYPES } from "../_constants";
import moment from "moment";

const updateEditStats = (currentState, newType) => {
  const { HOLD, SINGLE, NODE, SCALE, MOVE } = EDITING_TYPES;
  const typeKey = newType.toLowerCase();
  currentState[typeKey] = (currentState[typeKey] || 0) + 1;
  currentState.node_adjusting_time_in_seconds |= 0;

  if (currentState.node_adjust_started_at) {
    currentState.node_adjusting_time_in_seconds += moment().diff(
      currentState.node_adjust_started_at,
      "seconds"
    );
  }

  if ([HOLD, SINGLE, NODE, SCALE, MOVE].includes(newType)) {
    currentState.node_adjust_started_at = moment();
  } else {
    currentState.node_adjust_started_at = false;
  }

  // console.log(currentState);
  return currentState;
};

export function trackReducer(state = {}, action) {
  switch (action.type) {
    case trackConstants.INIT:
      return {
        started_at: moment(),
      };
    case trackConstants.NODE_ADJUST:
    case trackConstants.YAW_ADJUST:
      return updateEditStats(state, action.editingType);
    default:
      return state;
  }
}
