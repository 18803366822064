import { globalConstants, SCREEN_MODES } from "../_constants";
import { isEndpointCorrectionMode } from "../_helpers/utils";

const initialState = {
  frameIndex: 0,
  screenMode: SCREEN_MODES.PREVIEW,
  viewerHeight: 50, // (percent) for horizontal split view
  viewerWidth: 50, // (percent) for vertical split view
  pagination: false, // progressive view
  nodeIncrement: localStorage.getItem("node_increment") || 20, // Number of increment nodes for progressive viewing
  pageStart: 0,
  pageEnd: 0,
  endpointCorrection: isEndpointCorrectionMode(),
  reconPathVisible: false,
};

export function globalReducer(state = initialState, action) {
  switch (action.type) {
    case globalConstants.SELECT_FRAME:
      return {
        ...state,
        frameIndex: action.frameIndex,
      };
    case globalConstants.OPEN_PREVIEW:
      return {
        ...state,
        screenMode: SCREEN_MODES.PREVIEW,
      };
    case globalConstants.CLOSE_PREVIEW:
      return {
        ...state,
        screenMode: SCREEN_MODES.DRAWING,
      };
    case globalConstants.OPEN_PANO_VIEW:
      return {
        ...state,
        screenMode: SCREEN_MODES.PANO_VIEW,
      };
    case globalConstants.CLOSE_PANO_VIEW:
      return {
        ...state,
        screenMode: SCREEN_MODES.PREVIEW,
      };
    case globalConstants.OPEN_SPLIT_VIEW:
      return {
        ...state,
        screenMode: SCREEN_MODES.SPLIT_VIEW,
      };
    case globalConstants.OPEN_SPLIT_VIEW_VERTICAL:
      return {
        ...state,
        screenMode: SCREEN_MODES.SPLIT_VIEW_VERTICAL,
      };
    case globalConstants.CHANGE_VIEWER_HEIGHT:
      return {
        ...state,
        viewerHeight: action.viewerHeight,
      };
    case globalConstants.CHANGE_VIEWER_WIDTH:
      return {
        ...state,
        viewerWidth: action.viewerWidth,
      };
    case globalConstants.CHANGE_NODE_INCREMENT:
      localStorage.setItem("node_increment", action.nodeIncrement);
      return {
        ...state,
        nodeIncrement: action.nodeIncrement,
      };
    case globalConstants.ACTIVATE_PAGINATION:
      return {
        ...state,
        pagination: action.pagination,
      };
    case globalConstants.SET_PAGE_WINDOW:
      return {
        ...state,
        pageStart: action.pageStart,
        pageEnd: action.pageEnd,
      };
    case globalConstants.TOGGLE_RECON_PATH_VISIBILITY:
      return {
        ...state,
        reconPathVisible: !state.reconPathVisible,
      };
    default:
      return state;
  }
}
